import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild, NavigationExtras,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppUiHelper } from '@shared/common/ui/ui-controller-helper';
import {AppMarkets} from "@shared/AppEnums";
import {AppConsts} from "@shared/AppConsts";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {

    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        AppUiHelper.hideSideBar(); 
        
        let hasGeneralMarketStorePermission = this._permissionChecker.isGranted('Pages.Markets.GeneralMarket.Store');
        let hasMicrosoftStorePermission = this._permissionChecker.isGranted('Pages.Markets.Microsoft.Store')
        let hasAdobeStorePermission = this._permissionChecker.isGranted('Pages.Markets.Adobe.Store')
        let hasDealsPermission = this._permissionChecker.isGranted('Pages.Markets.Deals')
		
		 if(this._sessionService.user &&
             this._sessionService.user.tenantId !== null &&
             UrlHelper.isGeneralStoreUrl(state.url) &&
             (hasGeneralMarketStorePermission || hasMicrosoftStorePermission || hasAdobeStorePermission) ){
            return true
        }
        else if(this._sessionService.user && this._sessionService.user.tenantId !== null && UrlHelper.isGeneralStoreUrl(state.url)){
            this._router.navigate(['/app/cart/items']);
            return false;
        }

        let hasProductInformationUpdatePermission = this._permissionChecker.isGranted('Pages.Products.UpdateProductInformation');
        let hasLandingPageConfigPermission = this._permissionChecker.isGranted('Pages.Administration.LandingPageCustomization')
        if(this._sessionService.user && UrlHelper.isLandingPageUrl(state.url)){
            const page = state.root.queryParams['page']
            if(hasLandingPageConfigPermission ||
                 hasProductInformationUpdatePermission ||
                (hasGeneralMarketStorePermission && page == AppConsts.LandingPageGeneralDefaultConfig) ||
                (hasMicrosoftStorePermission && page == AppConsts.LandingPageCloudDefaultConfig) ||
                (hasDealsPermission && page == AppConsts.LandingPageEnterpriseDefaultConfig)
            ){
                return true;
            }else{
                this._router.navigate(['/app/main/partner-center']);
                return false;
            }
        }
        
        if (!this._sessionService.user){
            if(state.url.indexOf('/app/main/store') >= 0) { //Points to General Market 
                return true;    
            }
            if(UrlHelper.isLandingPageUrl(state.url)) { 
                return true;
            }
        }

        // Special temp case. Admin does not have a cart. Redirect it to the host dashboard. 
        // The tenant has no dashoard yet (Currently cart it the default location to go to)
        if ((this._sessionService.user && this._sessionService.user.tenantId == null) &&
            state.url === '/app/main/partner-center') {
            this._router.navigate([this.selectBestRoute()]);
            return true;
        }

        if (state && (UrlHelper.isInstallUrl(state.url)
            || UrlHelper.isGeneralStoreUrl(state.url))
            || state.url.indexOf(`/app/main/store?market=${AppMarkets.Microsoft}`) > -1 //Points to microsoft market
            || state.url.indexOf('main/markets/cisco') > -1
            || state.url.indexOf('main/markets/ibm') > -1
        ) {
            return true;
        }
      
        if (!this._sessionService.user && state.url.indexOf('notifications') > -1) {
            this._router.navigate(['/account/login']);
           return false;
       }
       
        if (!this._sessionService.user) {
            const queryParams = {
                market: AppMarkets.GeneralMarket 
            };
            const navigationExtras: NavigationExtras = {
                queryParams: queryParams
            };
              
            this._router.navigate([AppConsts.DigitalCommercePath], navigationExtras); 
            return false;
        }

        if (UrlHelper.isLandingPageUrl(state.url) && 
            !hasGeneralMarketStorePermission && 
            !hasMicrosoftStorePermission && 
            this._sessionService.user != null) {
            this._router.navigate(['/app/main/partner-center']);
            return false;
        }
        
        if (!route.data || !route.data['permission']) {
            return true;
        }

        if (this._permissionChecker.isGranted(route.data['permission'])) {
            return true;
        }
        
        this._router.navigate([this.selectBestRoute()]);
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string { 
        if (!this._sessionService.user) {
            return '/account/login';
        }

        if (this._permissionChecker.isGranted('Pages.Tenant.Dashboard')) {
            return AppConsts.DigitalCommercePath + '?page=' + AppConsts.LandingPageGeneralDefaultConfig; //Points to General Market
        }

        if (this._permissionChecker.isGranted('Pages.Tenants')) {
            return '/app/admin/tenants';
        }

        if (this._permissionChecker.isGranted('Pages.Administration.Users')) {
            return '/app/admin/users';
        }

        return '/app/notifications';
    }
}
